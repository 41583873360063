body {
  background: #FFF;
  background-size: cover;
  color: #1b1b1b;
}

::-webkit-input-placeholder {
  opacity: 1;
  color: #a8a8a8;
}

:-moz-placeholder {
  opacity: 1;
  color: #a8a8a8;
}

::-moz-placeholder {
  opacity: 1;
  color: #a8a8a8;
}

:-ms-input-placeholder {
  opacity: 1;
  color: #a8a8a8;
}

/* Color Variable */
:root {
  --primary: #ff6a27;
  --secondary: #ff6a27;
  --body: #1D1A40;
  --box: #35354F80;
  --grey: #27273E;
  --light-grey: #FFFFFF29;
  --white: #ffffff;
  --white2: #a1a1aa;
  --dark-blue: #100F27;
  --text-color: #a1a1aa;
  --text2-color: #71717a;
  --text3-color: #18181b;
  --gunmetal: #2E2E46;
  --crayola: #33334A;
  --charcoal: #35354F;

}

/* Color Variable */