@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');

/* Reset CSS */
* {
    padding: 0px;
    margin: 0px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

*:focus {
    outline: 0;
}

html,
body {
    overflow-x: hidden;
}

body {
    margin: 0;
    padding: 0;
    font-size: 15px;
    font-family: 'Inter', sans-serif;
    min-height: 100vh;
}

ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

:focus {
    outline: 0;
}

a,
a:link {
    text-decoration: none;
    outline: none;
}

.menu_bar {
    display: none;
}

p {
    padding: 2px 0;
    margin: 0;
    font-size: 16px;
}

a:focus {
    outline: none !important;
}

:hover {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
}

img {
    border: 0;
    max-width: 100%;
    height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    font-weight: 400;
}

/* Reset CSS */


body.modal-open {
    padding: 0 !important;
}

.modal-backdrop {
    background: rgba(255, 255, 255, 0.5) !important;
    backdrop-filter: blur(10px);
    opacity: 1 !important;
}

.site-wrap.for-dashboard {
    /* background: url('../src/assets/images/dash-bg.jpg') top center no-repeat;
    background-size: cover; */
}

.container {
    max-width: 1310px;
}

.theme-btn {
    display: inline-block;
    border: none;
    cursor: pointer;
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
    border-radius: 50px;
    font-size: 15px;
    font-weight: 600;
    color: var(--white);
    padding: 8px 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.theme-btn::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    opacity: 0;
    height: 100%;
    background: #d56836;
    transition: all 0.5s ease;
}

.theme-btn:hover {
    color: var(--white);
}

.theme-btn:hover::before {
    width: 100%;
    opacity: 1;
}

.theme-btn span {
    position: relative;
    z-index: 1;
}

.theme-btn.full {
    display: block;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.theme-btn svg {
    font-size: 18px;
    transform: translateY(-1px);
}

.theme-btn.bdr {
    background: none;
    border: 1px solid var(--primary);
    color: var(--primary);
}

.theme-btn.bdr:hover {
    color: var(--white);
}

.theme-btn.connected {
    background: var(--charcoal);
    color: #FFF !important;
    border: 1px solid var(--charcoal) !important;
}

.theme-btn.connected::before {
    opacity: 0;
}

.theme-btn.large {
    padding-top: 14px;
    padding-bottom: 14px;
    min-width: 85px;
}

.theme-btn.btn-min {
    min-width: 180px;
}

.train_content_head.main p {
    font-weight: 600;
}

/* .train_content_head.main h2::after {
    content: "";
    width: 2px;
    background-color: #a1a1aa;
    height: 21px;
    display: inline-block;
    position: relative;
    top: 3px;
} */

.website_pop .form-check-input:checked {
    background-color: #ff6a27;
    border-color: #ff6a27;
}

.website_pop .form-check-input:focus {
    outline: 0;
    border-color: #ff6a27;
    box-shadow: none;
}

.accordion .form-check input {
    padding-left: 0 !important;
    width: 18px;
    height: 18px;
}

.accordion .form-check-input:checked {
    background-color: #ff6a27;
    border-color: #ff6a27;
}

.accordion .form-check-input:focus {
    outline: 0;
    border-color: #ff6a27;
    box-shadow: none;
}

.accordion label {
    font-size: 13px;
    font-weight: 500;
}

.relposition {
    position: absolute;
    z-index: 9;
}

.off-btn {
    display: inline-block;
    border: none;
    cursor: pointer;
    background: #f4f4f5;
    border: 1px solid #a1a1aa45;
    border-radius: 50px;
    font-size: 15px;
    font-weight: 600;
    color: #18181b;
    padding: 8px 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.off-btn::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    opacity: 0;
    height: 100%;
    background: #d4d4d4;
    transition: all 0.5s ease;
}

.off-btn:hover {
    color: #000;
}

.off-btn:hover::before {
    width: 100%;
    opacity: 1;
}

.off-btn span {
    position: relative;
    z-index: 1;
}

.login-wrap {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 110px 0;
}

/* .login-box{ background: var(--box); width: 416px; margin: 0 auto; border-radius: 12px; padding: 30px;} */
.login-box {
    width: 416px;
    margin: 0 auto;
    padding: 0 30px;
}

.symbol {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
    font-size: 40px;
}

.login-box h2 {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.2px;
    padding-top: 22px;
    text-align: center;
}

.input-wrap {
    margin-top: 20px;
}

.input-wrap label {
    font-size: 15px;
    padding-bottom: 12px;
    display: block;
}

.inp-outer {
    padding: 2px;
    background: #ededed;
    border-radius: 8px;
    position: relative;
}

.inp {
    width: 100%;
    border-radius: 7px;
    border: none;
    font-size: 15px;
    font-weight: 400;
    padding: 10px 16px;
}

.inp.radius {
    border-radius: 50px;
}

.inp-outer:has(> .inp:focus) {
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
}

.footer-right ul li a {
    font-size: 13px;
}

textarea.inp {
    height: 150px;
    display: block;
}

.input-wrap.alt .inp-outer {
    border-radius: 50px;
}

.input-wrap.alt .inp {
    border-radius: 50px;
}

.inp-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    font-size: 22px;
}

.or {
    position: relative;
    font-size: 12px;
    text-align: center;
}

.or span {
    display: inline-block;
    background: var(--body);
    padding: 0 12px;
    position: relative;
    z-index: 1;
}

.or::after {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.14);
}

.social-sign {
    position: relative;
    margin-top: 20px;
}

.social-sign button {
    width: 100%;
    text-align: center;
    background: var(--crayola);
    border: none;
    color: #FFF;
    padding: 9px 30px;
    border-radius: 50px;
    font-size: 14px;
}

.social-sign button:hover {
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) border-box;
}

.social-sign-icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.5s ease;
}

.log-option {
    margin-top: 20px;
}

.log-option a {
    color: var(--white);
}

.log-option a:hover {
    color: var(--primary);
}

.site-header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 9;
    transition: none;
}

.header-in {
    max-width: 1750px;
    margin: 0 auto;
}

.site-header.fixed {
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
    /* backdrop-filter: blur(0.5rem); */
}

.site-header-main {
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user-img-in {
    width: 32px;
    height: 32px;
}

.user-img-in img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    object-fit: cover;
}

.profile-link .dropdown-toggle {
    display: inline-flex;
    align-items: center;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    color: #FFF;
}

.profile-link .dropdown-toggle:focus {
    outline: none;
}

.profile-link {
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.profile-link .dropdown-menu {
    width: 236px;
    background: var(--white);
    border-radius: 20px;
    padding: 20px;
}

.profile-link .dropdown-menu ul li:first-child {
    margin-top: 0;
}

.profile-link .dropdown-menu ul li {
    margin-top: 10px;
}

.profile-link .dropdown-menu ul li a {
    color: #1b1b1b;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.profile-link .dropdown-menu ul li a:hover {
    color: var(--secondary);
}

.profile-link .dropdown-menu ul li a span {
    font-size: 20px;
    padding-right: 10px;
}

.site-nav {
    border: 1px solid #ff6a27;
    border-radius: 50px;
}

.site-nav ul {
    display: flex;
    align-items: center;
}

.site-nav ul li {
    margin: 0 25px;
}

.site-nav ul li a {
    color: var(--text2-color);
    display: inline-block;
    padding: 10px 0;
    font-weight: 500;
}

.site-nav ul li a:hover {
    color: #ff6a27;
}

.site-nav ul li a.active {
    color: #ff6a27;
    font-weight: 700;
}

.site-header.fixed .site-nav {
    border: 1px solid #fff;
}

.site-header.fixed .site-nav ul li a {
    color: #fff;
}

.site-wrap {
    padding-top: 70px;
    padding-bottom: 80px;
}

.welcome {
    padding-top: 160px;
}

.welcome-top {
    max-width: 810px;
    text-align: center;
    margin: 0 auto;
}

.welcome-top h1 {
    font-size: 60px;
}

.welcome-top h1 span {
    font-weight: 600;
}

.welcome-top h1 span.green {
    font-weight: 400;
    color: var(--primary);
}

.welcome-top p {
    padding: 15px 80px 25px 80px;
    color: var(--white);
}

.welcome-video {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 45px;
}

.welcome-video-in {
    height: 390px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    border: 1px solid #00D0B0;
    z-index: 5;
}

.welcome-video-in::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(180deg, rgba(29, 27, 65, 0.63) 0%, #00D0B0 119.7%);
    /* opacity: 0.8; */
    opacity: 0.4;
}

.welcome-video-in video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.play-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 2;
    cursor: pointer;
    transition: all 5s ease;
}

.play-btn img {
    width: 95px;
}

.created-by {
    max-width: 1750px;
    margin: 0 auto;
    margin-top: 100px;
}

.create-details {
    border-radius: 20px;
    border: 1px solid var(--primary);
    background: #FFF;
    height: 243px;
    margin-top: 30px;
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    transition: none;
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.1);
}

.create-details:hover .card__line {
    /* display: none; */
    opacity: 0;
}

.create-details:hover {
    border: 3px solid var(--primary);
}

.create-details-top {
    align-self: stretch;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.create-details-bottom {
    align-self: stretch;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.create-details h3 {
    font-size: 20px;
    font-weight: 600;
    color: var(--primary);
}

.create-details h4 {
    font-size: 64px;
    color: #e0e0e0;
    font-weight: 700;
}

.create-details p {
    opacity: 0.7;
    max-width: 75%;
    font-size: 14px;
    color: #000;
    font-weight: 500;
}

.create-details-bottom span {
    font-size: 60px;
    color: var(--primary);
}

.video-project-wrap {
    margin-top: 10px;
}

.video-project {
    max-width: 1750px;
    margin: 0 auto;
    margin-top: 100px;
}

.video-project-single {
    background: var(--white);
    border: 1px solid #e7e7e7;
    border-radius: 20px;
    margin-top: 30px;
}

.video-project-16x9 {
    background: var(--white);
    border: 1px solid #ebebeb;
    border-radius: 20px;
    margin-top: 30px;
    max-width: 435px;
    height: 300px;
    margin-right: auto;
    margin-left: auto;
}

.video-project-img_16x9 {
    height: 217px;
    border-radius: 20px 20px 0px 0px;
    overflow: hidden;
}

.video-project-img_16x9 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-project-9x16 {
    background: var(--white);
    border: 1px solid #ebebeb;
    border-radius: 20px;
    max-width: 245px;
    height: 300px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
}

.video-project-img_9x16 {
    width: 100%;
    height: 217px;
}

.video-project-img_9x16 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-project-img {
    height: 300px;
    border-radius: 20px 20px 0px 0px;
    overflow: hidden;
}

.video-project-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-project-wrap .col-lg-3 {
    /* width: 20%; */
}

.video-project-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 14px 14px 14px;
}

.video-project-bottom h6 {
    font-size: 14px;
    font-weight: 500;
    color: var(--black);
}

.theme-modal.sweet-alert .modal-content {
    border: 1px solid var(--primary);
}

.video-project-bottom p {
    font-weight: 300;
    font-size: 12px;
    padding: 0;
}

.drop-style-1 .dropdown-toggle:focus {
    outline: none;
}

.drop-style-1 .dropdown-toggle {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    font-size: 20px;
}

.drop-style-1 .dropdown-toggle::after {
    display: none;
}

.drop-style-1 .dropdown-menu {
    background: #FFF;
    border-radius: 20px;
    padding: 15px;
    left: inherit !important;
    right: 0 !important;
}

.drop-style-1 .dropdown-menu ul li {
    margin-top: 16px;
}

.drop-style-1 .dropdown-menu ul li:first-child {
    margin-top: 0;
}

.drop-style-1 .dropdown-menu ul li a {
    display: flex;
    align-items: center;
    color: #000;
    font-size: 14px;
}

.drop-style-1 .dropdown-menu ul li a:hover {
    color: var(--primary);
}

.drop-style-1 .dropdown-menu ul li a svg {
    font-size: 20px;
    margin-right: 10px;
}

.image-block {
    max-width: 1200px;
    margin: 0 auto;
}

.image-block-single {
    margin-top: 90px;
}

.image-block-left {
    padding-right: 120px;
}

.image-block-left h5 {
    font-size: 16px;
    color: var(--secondary);
    font-weight: 500;
}

.image-block-left h6 {
    font-size: 21px;
    font-weight: 600;
    padding-top: 10px;
}

.image-block-left p {
    font-weight: 400;
    font-size: 14px;
    padding: 15px 0 0 0;
}

.image-block-left a {
    font-size: 21px;
    font-weight: 600;
    color: var(--white);
    display: inline-block;
    margin-top: 20px;
}

.image-block-left a:hover {
    color: #8FFFD7;
}

.image-block-right {
    text-align: right;
}

.image-block-single:nth-child(even) [class*='col-'] {
    order: 2;
}

.image-block-single:nth-child(even) [class*='col-']+[class*='col-'] {
    order: 1;
}

.image-block-single:nth-child(even) .image-block-right {
    text-align: left;
}

.image-block-single:nth-child(even) .image-block-left {
    padding-right: 0;
    padding-left: 120px;
}

.webnair {
    /* background: url('../src/assets/images/pattern.png') no-repeat top center; */
    background-size: 100% auto;
    padding: 200px 0 70px 0;
}

.webnair-in {
    max-width: 1500px;
    margin: 0 auto;
    border-radius: 20px;
    background: var(--white);
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(17px);
    padding: 90px 0;
}

.webnair-main {
    max-width: 1200px;
    margin: 0 auto;
}

.webnair-right h5 {
    font-size: 16px;
    color: var(--primary);
    font-weight: 500;
}

.webnair-right h6 {
    font-size: 21px;
    font-weight: 600;
    padding-top: 10px;
}

.webnair-right p {
    font-weight: 400;
    font-size: 14px;
    padding: 15px 0 0 0;
}

.site-footer {
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
    padding: 15px 0;
}

.footer-main {
    max-width: 1750px;
    margin: 0 auto;
}

.footer-in {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-left p {
    margin: 0;
    padding: 0;
    font-weight: 500;
    color: #FFF;
    font-size: 13px;
}

.footer-left p a {
    color: blue;
}

.footer-right ul {
    display: flex;
    align-items: center;
}

.footer-right ul li a {
    color: var(--white);
}

.footer-right ul li a:hover {
    color: blue;
}

.footer-right ul li {
    margin-left: 30px;
}

.footer-right ul li:first-child {
    margin: 0;
}

.footer-right ul li a img {
    width: 150px;
}

.logo img {
    width: 195px;
}

.site-wrap {
    min-height: calc(100vh - 69px);
}

.site-container {
    max-width: 1750px;
    margin: 0 auto;
}

.inpx {
    font-family: 'Inter', sans-serif;
    width: 100%;
    border-radius: 60px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    padding: 13px 30px;
}

textarea.inpx {
    border-radius: 16px;
    height: 250px;
}

textarea.large {
    height: 400px;
}

.inpx-wrap {
    position: relative;
    margin-top: 20px;
}

.inpx-icon {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-53%);
    font-size: 22px;
}

.inpx-wrap.icon-left .inpx {
    padding-left: 60px;
}

.project-search h2 {
    font-size: 24px;
    font-weight: 500;
}

.project-search .theme-btn {
    padding-top: 14px;
    padding-bottom: 14px;
}

select.inpx {
    background: url('../src/assets/images/icon/select.png') right 25px center no-repeat #FFF;
    appearance: none;
}


.integration {
    margin-top: 30px;
}

.integration-single {
    height: 156px;
    border-radius: 30px;
    background: #FFF;
    border: 1px solid var(--primary);
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
}

.integration-single:hover {
    border: 1px solid var(--primary);
}

.integration-single p {
    font-weight: 500;
    padding-top: 15px;
    color: #000;
}

.integration ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.integration ul li {
    padding: 0 10px;
    width: 25%;
}

.integration h2,
.connection h2 {
    font-size: 24px;
    font-weight: 500;
}

.connection {
    margin-top: 50px;
}

.connection-single {
    margin-top: 20px;
    border-radius: 30px;
    background: #FFF;
    min-height: 100%;
    display: flex;
    align-items: normal;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    padding: 50px 50px;
    border: 1px solid var(--primary);
}

.connection-single:hover {
    border: 1px solid var(--primary);
}

.connection-btn.connected {
    margin-bottom: 10px;
}

.connection-left {
    display: flex;
    align-items: center;
    color: #000;
}

.connection-logo {
    margin-right: 15px;
}

.connection-right button {
    border-radius: 36px;
    background: rgba(255, 255, 255, 0.20);
    border: none;
    color: var(--white);
    padding: 10px 25px;
}

.connection-right button.connected {
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
    color: #fff;
    border: 1px solid var(--primary);
}

.connection-btn {
    border-radius: 36px;
    background: rgba(255, 255, 255, 0.20);
    border: none;
    color: var(--white);
    padding: 10px 25px;
    border: 1px solid var(--primary);
}

.connection-btn.connected {
    background: var(--primary);
    color: #FFF;
    position: relative;
    overflow: hidden;
}

.connection-btn.connected span {
    position: relative;
    z-index: 1;
}

.connection-btn.connected:hover {
    color: var(--secondary);
}

.connection-btn.connected::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    opacity: 0;
    height: 100%;
    background: var(--white);
    transition: all 0.5s ease;
}

.connection-btn.connected:hover::before {
    width: 100%;
    opacity: 1;
}

.p_not {
    padding: 0 !important;
}

.help_model .modal-dialog {
    max-width: 900px !important;
}

.connection-btn.dark {
    background: #8FFFD7;
    color: var(--dark-blue);
    font-weight: 500;
}

.facebook-delete {
    margin-left: 25px;
}

.theme-modal .modal-dialog {
    max-width: 630px;
}

.theme-modal .modal-content {
    background: var(--white);
    border: none;
    box-shadow: none;
    border-radius: 30px;
    padding: 15px;
}

.modal-body h2 {
    font-size: 24px;
    font-weight: 500;
}

.group-btn {
    margin-top: 50px;
}

.common-inp {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #d5d5d5;
    background: var(--white);
    padding: 12px 20px;
    color: #000;
}

.common-inp:focus {
    border: 1px solid var(--primary) !important;
}

.common-inp.alt {
    border: 1px solid #d5d5d5;
    background: var(--white);
}

.modal-close {
    position: absolute;
    right: 0;
    top: -5px;
    font-size: 22px;
    cursor: pointer;
}

.create-cont {
    margin-top: 30px;
}

.create-cont h2 {
    font-size: 24px;
    font-weight: 500;
}

.top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
}

.top-bar-right {
    display: flex;
    align-items: center;
}

.top-bar-right .inpx {
    width: 160px;
}

.top-bar-right>* {
    margin-left: 10px;
}

.dimension {
    height: 227px;
    border-radius: 16px;
    background: rgba(29, 27, 65, 0.63);
    border: 2px solid rgba(29, 27, 65, 0.63);
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.dimension h5 {
    font-size: 32px;
    font-weight: 500;
}

.dimension p {
    font-weight: 300;
}

.dimension.select {
    border: 2px solid #8FFFD7;
}

.template-list {
    padding-top: 50px;
}

.template-list h2 {
    font-size: 24px;
    font-weight: 500;
}

.template-list ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.template-list ul li {
    padding: 0 10px;
    width: 16.65%;
}

.template-single {
    margin-top: 20px;
}

.template-single_dynmic {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.template-img {
    height: 640px;
    border-radius: 20px;
    width: 360px;
    overflow: hidden;
}

.template-img_16X9 {
    max-width: 635px !important;
    height: 360px !important;
}

.template-img_16X9 img {
    border-radius: 20px;
}

.template-img img {
    width: 100%;
    height: 100%;
    /* object-fit: contain; */
    border-radius: 20px;
}

.site-container.for-editor {
    max-width: fit-content;
    padding: 0 14px;
}

.editor-wrap {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 15px;
}

.editor-left {
    width: 400px;
    display: flex;
    justify-content: space-between;
}

.editor-left-nav {
    width: 84px;
    padding-top: 2px;
}

.innerTab.full2 .nav-item {
    margin-bottom: 10px;
    padding: 0 2px;
}

.editor-left-nav i {
    font-size: 20px;
}

.editor-left-cont {
    width: 100%;
    background: var(--dark-blue);
    border-radius: 25px;
    padding: 18px;
}

.navTab {
    display: block !important;
}

.navTab .nav-link {
    height: 52px;
    width: 52px;
    text-align: center;
    color: var(--white);
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    transition: all 0.5s ease;
    padding: 0;
}

.navTab .nav-link.active {
    height: 52px;
    width: 52px;
    background: rgba(29, 27, 65, 0.63);
    color: #8FFFD7;
}

.story-list-single {
    border-radius: 30px;
    border-radius: 30px;
    padding: 20px 25px;
    background: rgba(29, 27, 65, 0.63);
    margin-top: 12px;
    border: 1px solid rgba(29, 27, 65, 0.63);
}

.story-list-single.active {
    border: 1px solid #8FFFD7;
}

.story-list-single:first-child {
    margin-top: 0;
}

.story-list-single p {
    font-size: 13px;
    font-weight: 300;
}

.left-scroll {
    height: 628px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: -15px;
    padding-right: 15px;
}

.left-scroll::-webkit-scrollbar {
    width: 5px;
}

.left-scroll::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.30);
    border-radius: 50px;
}

.left-scroll::-webkit-scrollbar-thumb {
    background: #00D0B0;
    border-radius: 50px;
}

.font-block h5 {
    font-size: 14px;
    font-weight: 500;
}

.font-block h6 {
    font-size: 11px;
    font-weight: 300;
}

.voice.font-block h6 {
    font-size: 16px;
    font-weight: 500;
    color: var(--text3-color);
}

.inp-small {
    width: 100%;
    border-radius: 40px;
    /* background: rgba(29, 27, 65, 0.63); */
    border: 1px solid var(--primary);
    color: var(--text2-color);
    padding: 10px 20px;
    font-size: 13px;
}

.type-div {
    display: inline-flex;
    align-items: center;
    border-radius: 40px;
    background: rgba(29, 27, 65, 0.63);
    padding: 0 20px;
    height: 40px;
    margin-top: 8px;
    justify-content: space-between;
    width: 100%;
}

.type-div span {
    font-size: 20px;
    display: block;
    margin-left: 15px;
    height: 100%;
    display: flex;
    align-items: center;
}

.type-div span.active {
    color: #8FFFD7;
}

.type-div span:first-child {
    margin-left: 0;
}

textarea.inp-small {
    height: 165px;
    padding-top: 20px;
}

.font-size {
    height: 40px;
    border-radius: 40px;
    background: rgba(29, 27, 65, 0.63);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.row.sm {
    margin-left: -4px;
    margin-right: -4px;
}

.row.sm [class*='col-'] {
    padding-left: 4px;
    padding-right: 4px;
}

.inp-small.center {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}

.color-indicator {
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 40px;
    background: rgba(29, 27, 65, 0.63);
    padding: 0 20px;
}

.color-box {
    border-radius: 5px;
    background: #18185E;
    width: 23px;
    height: 23px;
}

.color-val {
    font-size: 13px;
    padding-left: 10px;
}

.color-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 8px;
}

.color-group span {
    width: 24px;
    height: 24px;
    background: #18185E;
    margin: 6px 6px 0 0;
}

.opt-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #a1a1aa;
    border: 1px solid #a1a1aa45;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    background-color: #FFF;
}

.switch input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
    background-color: var(--primary);
}

.switch .slider.round {
    border-radius: 34px;
}

.switch .slider.round:before {
    border-radius: 50%;
}

.slidecontainer {
    width: calc(100% - 80px);
}

.slidecontainer input {
    width: 100%;
}

.sliderN {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 3px;
    background: #d3d3d3;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 50px;
}

.sliderN::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background: var(--primary);
    border-radius: 100%;
    cursor: pointer;
}

.sliderN::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: #00D0B0;
    cursor: pointer;
    border-radius: 100%;
}

.innerTab {
    margin-top: 20px;
    margin-bottom: 10px;
}

.innerTab.full {
    justify-content: space-between;
}

.innerTab.full2 {
    justify-content: center;
}

.innerTab .nav-link {
    padding: 0;
    color: #D9D9D9;
    font-weight: 300;
    font-size: 14px;
}

.innerTab .nav-link.active {
    color: var(--white);
    font-weight: 600;
}

.innerTab .nav-item {
    margin-left: 20px;
}

.innerTab .nav-item:first-child {
    margin-left: 0;
}

.photo-single {
    height: 125px;
    position: relative;
    margin-top: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.photo-single img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.photo-scroll {
    height: 495px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: -15px;
    padding-right: 15px;
}

.photo-scroll::-webkit-scrollbar {
    width: 5px;
}

.photo-scroll::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.30);
    border-radius: 50px;
}

.photo-scroll::-webkit-scrollbar-thumb {
    background: #00D0B0;
    border-radius: 50px;
}

.video-duration {
    position: absolute;
    left: 15px;
    bottom: 15px;
    font-size: 14px;
}

.upload {
    border-radius: 30px;
    height: 165px;
    background: #FFF;
    border: 1px dashed var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    text-align: center;
}

.upload svg {
    font-size: 30px;
}

.upload p {
    font-size: 13px;
    padding: 10px 0 0 0;
}

.upload input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
}

.innerTab.alt {
    border-top: 1px solid var(--white);
    padding-top: 15px;
}

.audio-single {
    border-radius: 30px;
    background: #00927B;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    margin-bottom: 10px;
}

.audio-left {
    display: flex;
    align-items: center;
}

.audio-left p {
    font-size: 14px;
    padding-left: 8px;
}

.infoTxt {
    font-size: 14px;
}

.audio-list {
    padding-top: 10px;
}

.position ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;
}

.position ul li {
    padding: 0 4px;
}

.position-single {
    width: 33px;
    border-radius: 6px;
    background: rgba(29, 27, 65, 0.63);
    border: 1px solid rgba(29, 27, 65, 0.63);
    height: 33px;
    margin-top: 8px;
}

.position-single.active {
    border: 1px solid #8FFFD7;
}


.audio-scroll {
    height: 616px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: -15px;
    padding-right: 15px;
}

.audio-scroll::-webkit-scrollbar {
    width: 5px;
}

.audio-scroll::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.30);
    border-radius: 50px;
}

.audio-scroll::-webkit-scrollbar-thumb {
    background: #00D0B0;
    border-radius: 50px;
}

.audio-scroll-2 {
    height: 617px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: -15px;
    padding-right: 15px;
}

.audio-scroll-2::-webkit-scrollbar {
    width: 5px;
}

.audio-scroll-2::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.30);
    border-radius: 50px;
}

.audio-scroll-2::-webkit-scrollbar-thumb {
    background: #00D0B0;
    border-radius: 50px;
}

.position {
    width: 150px;
}

.editor-right {
    width: calc(100% - 405px);
    padding-left: 30px;
}

.editable {
    height: auto;
    border-radius: 43px;
    margin-top: 30px;
    /* background: var(--dark-blue); */
}

.editor-controller {
    margin-top: 20px;
    margin-bottom: 20px;
    max-height: 290px;
    border-radius: 43px;
    background: var(--dark-blue);
    padding: 20px;
}

.editor-right {
    /* height: calc(100vh - 325px); */
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 15px;
}

.editor-right::-webkit-scrollbar {
    width: 5px;
}

.editor-right::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.30);
    border-radius: 50px;
}

.editor-right::-webkit-scrollbar-thumb {
    background: #00D0B0;
    border-radius: 50px;
}

.control-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 100px;
    background: rgba(29, 27, 65, 0.30);
    height: 36px;
    padding: 0 30px;
}

.control-bar-left ul {
    display: flex;
    align-items: center;
}

.control-bar-left ul li {
    font-size: 18px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.control-bar-left ul li:first-child {
    margin-left: 0;
}

.control-bar-left ul li span {
    font-size: 12px;
    font-weight: 300;
    padding-left: 5px;
}

.slidecontainer.alt {
    width: 100px;
    transform: translateY(-5px);
}

.timeline {
    padding: 30px 0 0 0;
}

.timeline ul {
    display: flex;
    align-items: center;
}

.timeline ul li {
    margin-left: 10px;
}

.timeline ul li:first-child {
    margin-left: 0;
}

.timeline-single {
    width: 148px;
    height: 82px;
    border-radius: 16px;
}

.timeline-single img {
    width: 148px;
    height: 82px;
    object-fit: cover;
    border-radius: 16px;
}

.add-timeline {
    width: 146px;
    height: 80px;
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: rgba(29, 27, 65, 0.30);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.add-timeline svg {
    font-size: 24px;
}

.timeline-wrap {
    padding: 0 30px;
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
}

.audio-timeline {
    background: #7A5986;
    height: 36px;
    border-radius: 12px;
    margin-top: 15px;
    padding: 0 30px;
    padding-left: 50px;
    position: relative;
}

.audio-timeline-in {
    background: url('../src/assets/images/audio.png') center center repeat-x;
    height: 36px;
}

.audio-timeline span {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    font-size: 20px;
}

.audio-timeline.alt {
    background: #5C89BD;
}

.stick {
    position: absolute;
    left: 20%;
    top: 0;
}

.help-container {
    max-width: 1138px;
    margin: 0 auto;
}

.help-list {
    margin-top: 30px;
}

.help-container .project-search {
    margin-top: 80px;
}

.help-single {
    text-align: center;
    margin-top: 30px;
    padding: 0 25px;
    border: 1px solid #ff6a27;
    border-radius: 15px;
    padding: 30px;
    height: 213px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

.help-single h2 {
    font-size: 17px;
    padding-top: 25px;
    color: #000;
}

.help-single p {
    font-size: 14px;
    font-weight: 300;
    padding-top: 10px;
    opacity: 0.8;
}

.help-txt {
    text-align: center;
    max-width: 710px;
    margin: 0 auto;
    padding: 100px 0;
}

.help-txt h2 {
    font-size: 20px;
    font-weight: 500;
}

.help-txt p {
    font-size: 14px;
    opacity: 0.8;
    padding-top: 15px;
    font-weight: 400;
}

.help-txt .theme-btn {
    min-width: 374px;
}

.account-wrap {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    padding-top: 30px;
}

.account-nav {
    width: 230px;
}

.account-nav ul li {
    margin-top: 10px;
}

.account-nav ul li:first-child {
    margin-top: 0;
}

.account-nav ul li a {
    display: flex;
    align-items: center;
    height: 38px;
    border-radius: 50px;
    color: #1b1b1b;
    padding: 0 20px;
}

.account-nav ul li a svg {
    font-size: 20px;
    margin-right: 10px;
}

.account-nav ul li a.active {
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
    color: #FFF;
}

.account-wrap.large {
    max-width: 1400px;
}

.account-wrap.large .account-nav {
    width: 350px;
}

.account-wrap.large .account-cont {
    width: calc(100% - 350px);
}

.account-nav .nav-item {
    margin-top: 10px;
}

.account-nav .nav-item:first-child {
    margin-top: 0;
}

.account-nav .nav-link {
    display: flex;
    align-items: center;
    color: #000;
    height: 38px;
    border-radius: 50px;
    padding: 0 15px;
}

.account-nav .nav-link.active {
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
    color: #FFF;
}

.account-cont {
    width: calc(100% - 230px);
    padding-left: 70px;
}

.account-block {
    border-radius: 20px;
    border: 1px solid #e2e2e2;
    padding: 50px;
    margin-top: 30px;
}

.account-block.alt {
    margin-top: 0;
    min-height: 100%;
    min-height: 660px;
}

h2.account-title {
    font-size: 24px;
    font-weight: 600;
}

.account-block.sm {
    padding: 40px;
}

.account-block.first {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.account-profile-img {
    width: 127px;
    height: 127px;
    border-radius: 100%;
    margin: 0 auto;
    position: relative;
}

.account-profile-img img {
    width: 127px;
    height: 127px;
    object-fit: cover;
    border-radius: 100%;
}

.account-profile {
    text-align: center;
}

.account-profile h5 {
    padding-top: 25px;
}

.profile-img-edit {
    width: 35px;
    height: 35px;
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-size: 20px;
}

.profile-img-edit input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
}

.privacy-block {
    border-bottom: 1px solid #e2e2e2;
    padding: 40px 0;
}

.privacy-block:first-child {
    padding-top: 0;
}

.privacy-block:last-child {
    padding-bottom: 0;
    border: none;
}

.privacy-block h5 {
    font-weight: 600;
}

.privacy-block p {
    font-size: 14px;
    font-weight: 400;
    opacity: 0.9;
    padding: 15px 0 0 0;
}

.theme-table {
    margin: 0;
}

.theme-table tr th {
    font-weight: 300;
    padding: 0 0 15px 0;
}

.theme-table tr td {
    font-size: 13px;
    font-weight: 300;
    opacity: 0.8;
    padding: 10px 0;
    vertical-align: middle;
}

.table> :not(caption)>*>* {
    padding: 0;
    background: none;
    color: #FFF;
}

.table-icon {
    font-size: 20px;
}

.welcome-cont {
    text-align: center;
}

.no-cont {
    text-align: center;
    padding: 80px 0;
}

.no-cont h4 {
    font-weight: 600;
}

.login-footer {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 2;
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
}

.login-header .site-header {
    background: none;
    border: none;
}

.training-img {
    padding: 5px;
    background: #00D0B0;
    border-radius: 15px;
    margin-top: 15px;
}

.training-img img {
    width: 100%;
    border-radius: 10px;
}

.training-block {
    margin-top: 40px;
}

.training-block:first-child {
    margin-top: 0;
}

/* .video-project-wrap.alt .col-lg-3 {
    width: 25%;
} */

.video-project-img video,
.video-project-img iframe {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.help-icon img {
    width: 50px;
    background: var(--primary) !important;
    height: 70px !important;
    width: 70px !important;
    padding: 15px;
    border-radius: 10px;
    object-fit: contain;
}

.theme-btn.w-205 {
    min-width: 205px;
}

.trans-name {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.int-logo img {
    max-height: 60px;
}

.single-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.single-block h5 {
    font-size: 14px;
}

.single-block-icon {
    font-size: 20px;
}

.slidecontainer.full {
    width: 100%;
}

.sketch-picker {
    position: relative;
    z-index: 99;
}

.color-indicator {
    position: relative;
}

.uploadList {
    margin-top: 30px;
}

.uploaded-images ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -4px;
}

.uploaded-images ul li {
    padding: 0 4px;
}

.upload-image-single {
    width: 150px;
    border: 1px solid var(--primary);
    padding: 5px;
    border-radius: 10px;
}

.upload-image-single img {
    border-radius: 6px;
    width: 100%;
    height: 88px;
    object-fit: cover;
}

.uploadList .upload {
    border: 1px solid var(--primary);
}

.script-wrap {
    max-width: 1200px;
    margin: 0 auto;
}

.script-outer {
    background: var(--body);
    border-radius: 15px;
    padding: 30px;
    margin-top: 40px;
}

.script-in {
    border: 2px solid #3d3a64;
    border-radius: 15px;
}

.script-top {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #3d3a64;
    padding: 0 20px;
}

.script-top p span {
    color: var(--primary);
    font-size: 18px;
    font-weight: 500;
}

.script-mid .inpx {
    border: none;
}

.script-bottom {
    display: flex;
    align-items: center;
    height: 50px;
    border-top: 2px solid #3d3a64;
    padding: 0 20px;
    justify-content: flex-end;
}

.sound-control {
    border: 1px solid var(--primary);
    border-radius: 15px;
    padding: 30px;
    margin-top: 15px;
}

.rec-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.record-img {
    margin: 0 3px;
    border: 2px solid var(--primary);
    width: 50px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 15px;
}

.record-img svg {
    font-size: 18px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.spin {
    animation: spin 1000ms linear infinite;
}

.editable .editor_16X9 {
    width: 853px !important;
    height: 480px !important;
    background: none !important;
    border-radius: 0 !important;
    margin: 20px auto 0 auto;
    overflow: hidden;
    /* width: 100%; */
}

.editable .editor_9X16 {
    width: 360px !important;
    height: 640px !important;
    background: none !important;
    border-radius: 0 !important;
    margin: 0 auto;
    overflow: hidden;
}

.editable .canvasEditor-main {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    background: #000;
    padding-top: 0;
}

.editable .video-container {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.editable .video-container video {
    position: absolute;
    top: 0;
    width: 100% !important;
    height: 100%;
    object-fit: contain;
    display: block;
}

.editor-bar {
    /* background: rgba(29, 27, 65) !important; */
    background: rgb(57 54 98) !important;
    margin: 18px 0 0 0;
    height: 40px !important;
    max-width: 854px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-radius: 30px;
    width: 100%;
}

.editor-bar-left ul {
    display: flex;
    height: 100%;
    align-items: center;
}

.editor-bar-left ul li {
    margin: 0px 5px;
}

.whiteFont {
    color: #fff;
    font-size: 20px;
}

.video-slide {
    margin-top: 5px;
    overflow: auto;
    display: flex;
    padding-bottom: 8px;
    margin-bottom: -4px;
    cursor: pointer;
}

.video-slide::-webkit-scrollbar {
    height: 5px;
}

.video-slide::-webkit-scrollbar-thumb {
    background: #00D0B0;
    border-radius: 50px;
}

.video-slide:-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.30);
    border-radius: 50px;
}

.video-slide ul {
    display: flex;
    white-space: nowrap;
    position: relative;
    align-items: center;
    margin: 0 16px;
}

.editor_slide_bg {
    background: rgba(29, 27, 65, 0.60);
    padding: 10px 50px;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: end;
    margin: 0px 5px;
}

.video-slide ul li .editorReel-single-li {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: 79px !important; */
    margin-right: 9px;
    width: 175px;
    height: 95px;
    background: #121521;
    border: 2px solid #454545;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
}


/* .video-slide ul li .editorReel-single-li {
    width: 100%;
    height: 100%;
} */

.video-slide ul li .Sub-editorReel-single-li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 9px;
    width: 150px;
    height: 75px;
    background: #121521;
    border: 2px solid #454545;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
}

.IconDel {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2c3450;
}

.micON {
    position: absolute;
    top: 4px;
    left: 4px;
}

.Video_Project_Render {
    width: 100%;
}

.Video_Project_Render .progress {
    border-radius: 0 !important;

}

.Video_Project_Render .progress .progress-bar {
    background-color: #1d1b40 !important
}

.progress-bar-text {
    color: #fff;
    font-size: 13px;
    /* display: flex;
    justify-content: center; */
    font-weight: 700;
    /* position: absolute;
    top: 2px;
    left: 20%; */
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgb(0 126 107) 25%, transparent 25%, transparent 50%, rgb(0 126 107) 50%, rgb(0 126 107) 75%, transparent 75%, transparent);
}

.ck.ck-editor__editable_inline {
    border: 1px solid #f2efef !important;
    border-top: none !important;
}

.ck.ck-content p {
    color: #fff;
}

.up_img {
    width: 121px;
    border-radius: 10px;
    height: 82px;
    overflow: auto;
}

.public_modal.theme-modal .modal-body {
    padding: 40px 0 0 0;
}

.public_modal.theme-modal .modal-dialog {
    max-width: 900px;
}

.public_modal.theme-modal .img-wrraper img {
    height: 54px;
    object-fit: contain;
}

.public_modal.theme-modal ul {
    padding: 0 65px;
}

.public_modal.theme-modal .social_hover {
    width: 134px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.disable {
    filter: grayscale(1);
}

.public_modal.theme-modal p {
    color: #fff;
    font-size: 13px;
}

.public_modal.theme-modal .social_hover .icon_hover:hover {
    width: 115px;
    height: 115px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 100%;
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
    transition: none;
}

.public_modal.theme-modal label {
    color: #fff !important;
}

.public_modal.theme-modal input::placeholder {
    color: #fff !important;
}

.public_modal.theme-modal textarea::placeholder {
    color: #fff !important;
}


@keyframes progress-bar-stripes {
    0% {
        background-position-y: 1rem;
    }
}

.hero-banner {
    align-items: center;
    /* display: flex; */
    height: calc(100vh - 130px);
    min-height: 810px;
    position: relative;
    background: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/clipReel3.0_templates_data/Bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
}

.hero-banner:after {
    background: linear-gradient(180deg, #000 0, rgba(125, 185, 232, 0));
    content: "";
}

.hero-banner-video {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    padding-bottom: 56.25%;
}

.hero-banner:after {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.hero-banner-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* transform: translateY(-150px); */
}



/* Added Tapas */
.login-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    position: relative;
    padding: 0;
    flex-direction: row;
}

.login-left {
    width: 50%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-right {
    width: 50%;
}

.login-right-main {
    max-width: 600px;
    margin: 0 auto;
    border-radius: 20px;
    padding: 40px;
    border: 2px solid var(--primary);
}

.welcome-txt {
    max-width: 600px;
}

.login-right-main h2 {
    font-size: 22px;
}

.login-right-main h2 span {
    color: var(--primary);
    font-weight: 600;
}

.welcome-txt h2 {
    font-weight: 700;
    font-size: 50px;
}

.welcome-txt h2 span {
    color: var(--primary);
}

.welcome-txt p {
    padding: 15px 0 0 0;
}

.welcome-txt h3 {
    padding: 25px 0 0 0;
}

.log-option a {
    color: var(--primary);
}

/* .editor-right{ display: flex; align-items: center; justify-content: center; flex-direction: column;} */
.hero-banner {
    height: 100vh;
    min-height: 900px;
}

.welcome-top h1 {
    font-size: 50px;
    color: var(--white);
}

.created-by {
    margin-top: -100px !important;
    position: relative;
    z-index: 1;
    padding: 50px 0 0 0;
}

.created-by h2 {
    font-weight: 600;
}

/* .created-by h2{ color: var(--white);} */

.innerTab.full2 {
    justify-content: space-between;
}

.innerTab.full2 .nav-item {
    margin-left: 0;
}

.innerTab.full .nav-item {
    margin-left: 0;
}

h2.tabTitle {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 15px;
    color: var(--primary);
}

.innerTab {
    margin: 0 !important;
}

.photo-single.alt::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(to bottom, rgba(30, 87, 153, 0) 0%, rgba(0, 0, 0, 1) 100%);
}

.trans-name {
    z-index: 1;
    align-items: flex-end;
    padding-bottom: 15px;
}

.select-tile-style {
    z-index: 1;
}

.temp-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* .tabepaneIn-main {
    background: linear-gradient(90deg, #00d0b0 0.08%, #007664 101.7%);
    border-radius: 22px;
    padding: 40px;
} */

.tabRight {
    padding: 50px 90px;
}

.modalVid {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./assets/images/Ai_img.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 600px;
}

.theme-modal.ai_img_wrap.video-modal .modal-dialog {
    max-width: 991px;
}

.site-header {
    /* background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%); */
    background: #fff;
    /* border-bottom: 1px solid #ff6a27; */
}

.dash-header .site-header {
    background: none;
}

.dash-header .site-header.fixed {
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
    /* border-bottom: none; */
}

.welcome-video-img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.welcome-video-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.created-by {
    margin-top: 0 !important;
}

.clone-cont {
    max-width: 700px;
    margin: 0 auto;
    margin-top: 30px;
}

.clone-steps {
    padding: 60px;
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.navigate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.navigate span {
    cursor: pointer;
    font-weight: 600;
}

.clone-cont-bottom {
    margin-top: 20px;
}

.clone-cont-bottom .nav {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.clone-cont-bottom .nav-link {
    display: inline-flex;
    width: 30px;
    height: 6px;
    background: #cbcbcb;
    border-radius: 50px;
    padding: 0;
    pointer-events: none;
}

.clone-cont-bottom .nav-link.active {
    background: var(--primary);
}

.clone-img {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
}

.clone-img-holder {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    overflow: hidden;
}

.clone-img-holder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.clone-img-up {
    position: absolute;
    top: 52px;
    left: 54%;
    background: #ff6a27;
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
}

.clone-img-up input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}

.label {
    font-weight: 500;
}

.clone-steps h3 {
    font-weight: 500;
}

.clone-steps p {
    color: #838383;
}

.topic-list ul {
    margin: 0 -5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2px;
    margin-top: 20px;
    padding-bottom: 20px;
}

.topic-list ul li {
    padding: 0 5px;
    margin-top: 10px;
}

.topic-list-single {
    position: relative;
    border: 1px solid #dfdfdf;
    padding: 5px 20px;
    border-radius: 50px;
    font-weight: 500;
}

.topic-list-cont {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.topic-list-single input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
}

.topic-list-single:has(input:checked) {
    border: 1px solid var(--primary);
    color: var(--primary);
}

.clone-purpose ul {
    padding-bottom: 20px;
    padding-top: 20px;
}

.clone-purpose ul li {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 0 0 32px;
    margin-top: 20px;
}

.clone-purpose ul li .check-select {
    position: absolute;
    left: 0;
    top: 0;
    width: 22px;
    height: 22px;
}

.clone-purpose ul li p {
    padding: 0;
    color: #000;
    font-weight: 500;
}

.check-select-mark {
    width: 22px;
    height: 22px;
    border: 1px solid #c9c9c9;
    border-radius: 4px;
    display: inline-block;
    background: #FFF;
    position: relative;
}

.check-select-mark::after {
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    left: 2px;
    top: 2px;
    border-radius: 4px;
    background: var(--primary);
    opacity: 0;
}

.check-select input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 2;
}

.check-select input:checked~.check-select-mark {
    border: 1px solid var(--primary);
}

.check-select input:checked~.check-select-mark::after {
    opacity: 1;
}

.custom-purpose {
    border-top: 1px solid #f1f1f1;
    margin-top: 20px;
}

.custom-purpose .input-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.custom-purpose .common-inp.alt {
    width: calc(100% - 60px);
}

.add-purpose-btn {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background: var(--primary);
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 44px;
    cursor: pointer;
}

.clone-style {
    border-top: 1px solid #f1f1f1;
    margin-top: 30px;
}

.clone-style-single {
    margin-top: 30px;
}

.clone-style-txt {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.clone-style-txt span strong {
    cursor: pointer;
}

.clone-style-txt span small {
    color: #8d8d8d;
}

.clone-style-single h5 {
    font-weight: 600;
}

.clone-style-txt span.default {
    color: var(--primary);
}

.clone-img.block {
    display: block;
    text-align: center;
}

.clone-img.block .clone-img-holder {
    width: 100px;
    height: 100px;
    margin: 15px auto;
}

.heading-block h2 {
    font-weight: 600;
}

.heading-block p {
    font-size: 15px;
    font-weight: 500;
    color: var(--text2-color);
}

.clone-card-wrap {
    margin-top: 30px;
}

.clone-card {
    border-radius: 10px;
    border: 1px solid var(--primary);
    border-left: 4px solid var(--primary);
    border-bottom: 4px solid var(--primary);
    padding: 15px;
}

.clone-card-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.clone-card-profile {
    display: flex;
    align-items: center;
    margin-top: 20px;
    background: #ff6a2726;
    border-radius: 10px;
    padding: 12px;
}

.clone-card-profile-img {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 100%;
}

.clone-card-profile-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.clone-card-profile-txt {
    width: calc(100% - 50px);
    padding-left: 15px;
}

.clone-card-profile-txt h5 {
    font-weight: 700;
}

.clone-card-profile-txt p {
    padding: 0;
    font-weight: 500;
}

.clone-radiness {
    margin-top: 20px;
}

.clone-radiness h6 {
    text-transform: uppercase;
    color: #424242;
    font-size: 14px;
    font-weight: 600;
}

.radiness-graph {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.radiness-graph-left {
    display: flex;
    align-items: center;
    gap: 5px;
}

.radiness-graph-left span {
    display: inline-block;
    width: 3px;
    height: 20px;
    background: #ebebeb;
}

.radiness-graph-left span.active {
    background: var(--primary);
}

.radiness-stat {
    padding-left: 15px;
    font-weight: 700;
    color: var(--primary);
}

.clone-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.clone-meta-left p {
    padding: 0;
    line-height: 18px;
}

.clone-meta-right {
    display: flex;
    align-items: center;
    gap: 5px;
}

.clone-meta-right a {
    font-size: 20px;
    color: var(--primary);
}

.clone-meta-right span {
    font-size: 20px;
    color: var(--primary);
    cursor: pointer;
}


.studio-wrap {
    padding: 0 30px 0 0;
    margin: 17px 0 0 0;
}

.studio-left {
    width: 300px;
}

.studio-nav ul li a {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #707070;
    font-weight: 500;
    padding: 12px 15px;
    border-radius: 50px;
}

.studio-nav .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
    color: #FFF;
}

.studio-nav .nav-item {
    margin-bottom: 10px;
}

.studio-nav .nav-link {
    gap: 8px;
    color: #707070;
    font-weight: 500;
    padding: 12px 15px;
    border-radius: 50px;
}

.welcome-studio .nav-link {
    font-weight: 500;
    color: #707070;
    min-width: 100px;
    text-align: center;
    padding: 12px 15px;
    border-radius: 50px;
}

.welcome-studio.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
    color: #FFF;
}

.studio-nav .nav-link svg {
    font-size: 18px;
}

.studio-right {
    width: calc(100% - 300px);
    padding-left: 50px;
}

.pageTitle.flex {
    display: flex;
    align-items: center;
    gap: 15px;
}

.clone-radiness.alt .radiness-graph {
    border: 1px solid var(--primary);
    border-radius: 50px;
    padding: 0 15px;
    height: 46px;
}

.pageTitle h2 {
    font-weight: 600;
}

.clone-card-profile-img.alt {
    width: 32px;
    height: 32px;
}

.clone-radiness.alt .radiness-graph-left {
    margin-left: 15px;
}

.welcome-studio {
    margin-top: 30px;
    border: 1px solid #e6e6e8;
    min-height: 56px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 50px;
    padding: 0 10px;
    margin-left: 10px;
    justify-content: space-between;
}

.welcome-studio-left p {
    line-height: 16px;
    padding: 0;
    font-weight: 500;
}

.welcome-studio-left p small {
    color: var(--text2-color);
    font-size: 13px;
}

.welcome-studio-left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.studio-left-icon {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: var(--primary);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-size: 24px;
}

.clone-summary {
    margin-top: 40px;
}

.clone-summary-single {
    border: 1px solid #e6e6e8;
    border-radius: 15px;
    padding: 15px 20px;
    margin-top: 20px;
    height: 140px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.clone-summary-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.clone-summary-meta {
    display: flex;
    align-items: center;
}

.clone-summary-meta h5 {
    padding-left: 10px;
    font-weight: 500;
    color: var(--text2-color);
}

.clone-summary-meta small {
    font-weight: 500;
    font-size: 13px;
    color: var(--text2-color);
}

.summary-period select {
    border: 1px solid #e6e6e8;
    border-radius: 5px;
    font-size: 13px;
    padding: 8px;
    font-weight: 500;
    color: var(--text2-color);
}

.clone-summary-meta h2 span {
    opacity: 0.6;
}

.summary-title {
    font-weight: 500;
}

.clone-summary.for-action .clone-summary-single {
    border-radius: 0;
}

.clone-summary-single.mid {
    border-left: 0;
    border-right: 0;
}

.clone-summary-single.first {
    border-top-left-radius: 15px !important;
    border-bottom-left-radius: 15px !important;
}

.clone-summary-single.last {
    border-top-right-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
}

.clone-summary-single .studio-left-icon {
    width: 30px;
    height: 30px;
    font-size: 16px;
}

.train_content_head {
    display: flex;
    align-items: center;

}

.train_content_head h2 {
    font-size: 32px;
    font-weight: 500;
    display: inline-block;
    color: #000;

}

.Train_content_head h2 {
    font-size: 32px;
    font-weight: 500;
    color: #000;
}

.train_content_head h2 span {
    background: #838383;
    height: 23px;
    width: 3px;
    display: inline-flex;
    align-items: center;

}

.train_content_head p {
    display: flex;
    color: var(--white2);
    font-size: 13px;
    font-weight: 500;
}

.P_tags {
    border: 1px solid #fcd34d;
    border-radius: 30px;
    color: #ff6a27 !important;
    font-weight: 600 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px !important;
    padding: 8px;
    margin: 0;
    height: 24px;
    background-color: #fef3c7;
}

.p .train_content_search {
    display: flex;
}

.train_content_search .accordion .form-check {
    padding: 0;
}

.train_content_search .accordion .form-check .form-check-input {
    float: right;
}

.train_content_search .accordion .accordion-button:not(.collapsed) {
    background-color: transparent;
}

.train_content_search .accordion .accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none;
}

.train_content_search .accordion .accordion-button:hover {
    text-decoration: underline;
}

.train_content_search .accordion .accordion-body {
    padding-top: 0;
}


.train_content_search .accordion .spinner-border {
    width: 14px;
    height: 14px;
    border-width: 2px;
}

.train_content_search .accordion .spinner-border {
    color: #FFA500;
}

.train_content_search .accordion .spinner-border.r {
    color: #f87171;
}

.train_content_search input {
    border-radius: 30px;
}

.train_content_search input::placeholder {
    font-size: 13px !important;
    font-weight: 500;
    color: var(--text2-color) !important;
}

.train_content_search .form-control:focus {
    border-color: var(--primary);
    outline: 0;
    box-shadow: none;
}

.b_cricle {
    width: 12px;
    height: 12px;
    display: inline-block;
    background: #f87171;
    border-radius: 100%;
}

.g_circle {
    width: 12px;
    height: 12px;
    display: inline-block;
    background: #4ade80;
    border-radius: 100%;
}

.train_table .table> :not(caption)>*>* {
    padding: 0;
    background: none;
    color: #707070;
}

.widget-table {
    margin: 0;
}

.widget-table tr th {
    vertical-align: middle;
    font-weight: 500;
    background: rgba(29, 27, 65, 0.9);
    padding: 14px 15px;
    border-top: 1px solid #4a469d;
    border-bottom: 1px solid #4a469d;
}

.widget-table tr th:first-child {
    border-left: 1px solid #4a469d;
}

.widget-table tr th:last-child {
    border-right: 1px solid #4a469d;
}

.widget-table tr td {
    padding: 24px 15px;
    vertical-align: middle;
    border-bottom: 1px solid #4a469d;
    /* text-align: start; */
}

.widget-table tr th {
    background: #f7f7f7;
    color: var(--charcoal);
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
}

.widget-table tr th:first-child {
    border-left: 1px solid #e7e7e7;
}

.widget-table tr th:last-child {
    border-right: 1px solid #e7e7e7;
}

.widget-table tr td {
    border-bottom: 1px solid #e7e7e7;
}

.para_text {
    color: var(--white2);
}

.para_text2 {
    color: var(--text2-color);
}

.no_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.no_content b {
    font-size: 22px
}

.no_content span {
    font-size: 13px;
}

.no_content button {
    color: #fff;
}

.purpose_content_para p {
    color: #18181b;
    font-size: 16px;
}

.purpose_content_para textarea {
    min-height: 125px;
    width: 100%;
    padding: 10px;
    border: none;
    overflow-y: auto;
}

.purpose_content_para {
    position: relative;
}

.purpose_content_head h3 {
    font-size: 18px;
    color: var(--text3-color);
    font-weight: 500;
}

.purpose_main_para p {
    font-size: 13px;
    font-weight: 500;
}

.train_content_wrap .train_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.Train_content_head p {
    font-size: 13px;
    margin-bottom: 20px;
    font-weight: 500;
}

.Train_content_head h4 {
    color: #18181b;
    font-size: 18px;
    font-weight: 500;
}

.purpose_content_para p {
    padding-left: 20px;
    font-weight: 400;
}

.purpose_content_para::before {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    width: 2px;
    background: #ff6a27;
    height: 100%;
}

.purpose_main_content {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.f-13 {
    font-size: 13px;
}

.f-15 {
    font-size: 15px;
}

.f-16 {
    font-size: 16px;
}

.f-18 {
    font-size: 18px;
    font-weight: 500;
}

.purpose_main_content span {
    font-weight: 500;
}

.purpose_main_content p {
    font-size: 13px;
    font-weight: bold;
}

.purpose_main_content b {
    color: #dc2626;
    background-color: #f8717133;
    border: 2px solid #f8717133;
    padding: 5px 11px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-left: 10px;
}

.theme-modal.ai_img_wrap.voice-modal .modal-dialog {
    max-width: 600px;
}

.theme-modal.ai_img_wrap.content-modal .modal-dialog {
    max-width: 1000px;
}

.text-p3 {
    font-size: 13px;
    color: #18181b;
    font-weight: 600;
}

.text-p4 {
    font-size: 13px;
}

.modalClose {
    cursor: pointer;
}

.upload_content_wrap {
    position: relative;
    border: 1px solid #d1d1d1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    flex-direction: column;
    /* padding: 40px 10px; */
    padding: 27px 10px;
    background-color: #f4f4f5;
}

.side_Train_tab.nav-pills .nav-link {
    font-size: 14px;
    font-weight: 500;
}

.modal_top p {
    padding: 6px 0;
}

.upload_content_wrap img {
    max-width: 100%;
    height: auto;
}

.upload_content_wrap h3 {
    font-size: 16px;
}

.upload_content_wrap input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.text-brand {
    color: #ff6a27;
}

.dividline {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.dividline:after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: #d1d1d1;
    margin-left: 10px;

}

.dividline::before {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: #d1d1d1;
    margin-right: 10px;

}

[chatgpt-sidebar] {
    display: none !important;
}

[chatgpt-sidebar-popups] {
    display: none !important;
}

[grammarly-desktop-integration] {
    display: none !important;
}

[data-lastpass-root] {
    display: none !important;
}

.custom_content i {
    font-size: 17px;
    font-weight: 500;
    line-height: 2;
}

.addInstruction {
    display: flex;
    justify-content: space-between;
}

#EmailTags {
    padding: 0;
    overflow: hidden;
    flex-wrap: wrap;
    padding: 15px;
}

#EmailTags li {
    margin: 10px;
}

input::placeholder {
    font-size: 13px;
}

select {
    font-size: 13px;
}

#EmailTags input {
    width: 100%;
    border: none;
    background: transparent;
    color: var(--black);
}

.email_tag {
    position: relative;
    border-radius: 20px;
    border: 1px solid #a1a1aa45;
    padding: 7px 10px;
    color: #71717a;
    background: #fafafa;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    transition: all 0.3s;

}

.inp_radio_wrap input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    border-radius: 16px;
}

/* Track: webkit browsers */
.inp_radio_wrap input[type="range"]::-webkit-slider-runnable-track {
    height: 15px;
    background: #ccc;
    border-radius: 16px;
}

/* Track: Mozilla Firefox */
/*  slider progress trick  */
.inp_radio_wrap input[type="range"]::-moz-range-track {
    height: 15px;
    background: #ccc;
    border-radius: 16px;
}

/* Thumb: webkit */
.inp_radio_wrap input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: -407px 0 0 400px #ff6a27;
}


/* Thumb: Firefox */
.inp_radio_wrap input[type="range"]::-moz-range-thumb {
    height: 15px;
    width: 15px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #fff;
    box-shadow: -407px 0 0 400px #ff6a27;
}

.email_tag .content {
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.email_tag.active {
    background: var(--primary);
    color: #fff;
}

.email_tag .badge {
    border-radius: 100%;
    width: 13px;
    height: 17px;
    display: block;
    position: absolute;
    background: var(--primary);
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -8px;
    right: -6px;
    transition: all 0.3s;
}

.email_tag .badge i {
    font-size: 9px;
}

.theme-modal.ai_img_wrap.content-modal .modal-content {
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 0;
}

.theme-modal.ai_img_wrap.content-modal .modal-body {
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 0;
    padding-right: 0;
}

.theme-modal.ai_img_wrap.content-modal .modal-content {
    height: 700px;
    overflow: hidden;
}

.modal_top {
    padding: 10px 16px;
    border-right: 1px solid #a1a1aa45;
}

.content_modal {
    height: 612px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 20px;
    padding-right: 10px;
}

.side_Train_tab {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #a1a1aa45;
    border-top: 1px solid #a1a1aa45;
    height: 100%;
    background: #fafafa;
    border-radius: 0;
}

.side_Train_tab.nav-pills .nav-link {
    color: #71717a;
    border-radius: 0;
}

.side_Train_tab.nav-pills .nav-link.active {
    border-radius: 0;
    color: #000;
    background-color: #fff;
    border-top: 1px solid #a1a1aa45;
    border-bottom: 1px solid #a1a1aa45;
    border-left: 2px solid #ff6a27;
}

.side_Train_tab .nav-item:focus-visible {
    outline: none;
}

.close_icon {
    cursor: pointer;
}

.pop_card {
    border: 1px solid #a1a1aa45;
    border-radius: 18px;
    min-height: 190px;
    margin-top: 20px;
    padding: 8px 20px 20px 20px;
}

.pop_card:hover {
    background: #fafafa;
}

.pop_card span {
    border: 1px solid #a1a1aa45;
    border-radius: 100%;
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
}

.pop_card h3 {
    font-size: 13px;
    font-weight: 600;
    margin-top: 18px;
    color: #000;

}

.pop_card h4 {
    font-size: 13px;
    font-weight: 500;
    margin-top: 18px;
    color: #71717a;
}

.website_upload p {
    font-size: 13px;
    color: #000;
}

.training-block h3 {
    color: #000 !important;
}

.website_upload img {
    width: 165px;
    height: 110px;
    border: 1px solid #a1a1aa45;
    border-radius: 10px;

}

.auto_main {
    position: relative;
    display: flex;
    justify-content: center;
}

.autoWrapper {
    border: 1px solid #a1a1aa45;
    min-height: 200px;
    max-width: 90%;
    border-radius: 10px;
    margin: auto;
    position: absolute;
    background: #fff;
    z-index: 2;
    width: 100%;

}

.autoWrapper-2 {
    border: 1px solid #a1a1aa45;
    min-height: 200px;
    max-width: 78%;
    border-radius: 10px;
    margin: auto;
    position: absolute;
    top: 41px;
    background: #fff;
    z-index: 1;
    width: 100%;
}

.autoWrapper-3 {
    border: 1px solid #a1a1aa45;
    min-height: 200px;
    max-width: 86%;
    border-radius: 10px;
    margin: auto;
    position: absolute;
    top: 21px;
    background: #fff;
    z-index: 1;
    width: 100%;
}

.reaload {
    width: 43px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 1px solid #a1a1aa45;
    margin-top: -12%;

}

.shadow-auto {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1)
}

.taking_modal_img {
    border: 1px solid #a1a1aa45;
    display: flex;
    justify-content: center;
    height: 40px;
    width: 40px;
    align-items: center;
    margin-right: 20px;
    border-radius: 100%;
}

.activity .btn-check:checked+.btn,
.activity:not(.btn-check)+.btn:active,
.activity .btn:first-child:active,
.activity .btn.active,
.activity .btn.show {
    color: #000;
    background-color: #a1a1aa45;
    border: 1px solid #a1a1aa45;
}

.activity .btn-outline-primary {
    border: 1px solid #a1a1aa45;
    color: var(--text2-color);
    display: flex;
    align-items: center;

}

.activity .btn-group {
    height: 48px;
}

.activity .btn-check+.btn:hover {
    color: var(--text2-color);
    border-color: #a1a1aa45;
}

.train_content_search .inpx-icon {
    position: absolute;
    /* top: 20px; */
    display: flex;
    align-items: center;
    left: 8px;
}

.train_content_search .inpx-icon svg {
    font-size: 17px !important;
    margin-left: 3px;
}

.train_content_search input {
    padding-left: 30px;
    padding-top: 4px;

}

.train_nav.welcome-studio .nav-link {
    padding: 6px 15px;

}

.train_content_search input::placeholder {
    color: #a1a1aa;
    font-size: 14px;
}

.theme-modal.feed .modal-dialog {
    max-width: 530px;
}

.feed_warp h2 {
    color: #000;
    font-size: 20px;
}

.feed_warp.email p {
    font-size: 13px;
}

.feed_warp.email h2 {
    color: #000;
    font-size: 17px;
}

.email_wrap {
    display: flex;
    justify-content: space-between;
    border: 1px solid #a1a1aa;
    padding: 14px;
    align-items: center;
    border-radius: 12px;
}

.studio_setting h3 {
    color: #52525b;
    font-weight: 600;
    font-size: 14px;
}


.studio_setting .studio-nav .nav-link {
    color: #707070;
    font-weight: 500;
    font-size: 13px;
    padding: 10px;
    display: flex;
    align-items: center;
}

.setting_upload_img {
    display: flex;
    align-items: center;
}

.setting_upload_img span {
    display: inline-block;
    width: 90px;
    height: 90px;
}

.setting_upload_img img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
}

.setting_upload_input {
    position: relative;
}

.setting_upload_input input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.settings_content h3 {
    color: #000;
    font-size: 16px;
}

.settings_content p {
    font-size: 14px;
    font-weight: 500;
}

.topics_wrap {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
}

.topics_wrap .col-4 {
    margin-right: 20px;
}

.settings_content .topics_elm {
    font-size: 13px;
    font-weight: 500;
    color: var(--text2-color);
}

.yourPlan_wrapper {
    border-radius: 10px;
    border: 1px solid #a1a1aa45;
    background: #fafafa;
    max-width: 575px;
    height: 195px;
    padding: 20px;
    position: relative;
}

.yourPlan_wrapper h4 {
    color: var(--white2);
}

.yourPlan_wrapper .head span {
    display: inline-block;
    font-weight: 500;
}

.setting_side_wrap {
    background: #fafafa;
    height: 831px;
    overflow-y: auto;
    padding: 20px 20px;
}

.setting_right_side_wrap {
    padding-left: 19px;
    padding-bottom: 50px;
    padding-top: 20px;
    padding-right: 20px;
    width: 100%;
    height: 830px;
    overflow-y: auto;
    overflow-x: hidden;
}

.yourPlan_wrapper .head::before {
    content: "";
    background: var(--primary);
    width: 2px;
    height: 32px;
    display: inline-block;
    position: absolute;
    margin: 0 1px 0 2px;
}

.yourPlan_wrapper p {
    color: var(--text-color);
    font-size: 13px;
    margin-top: 10px;
}

.bill_history {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 575px;
    margin-top: 20px;
}

.Usage_wrap {
    border: 1px solid #a1a1aa45;
    background: #fafafa;
    max-width: 575px;
    min-height: 160px;
    padding: 20px;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: none;
}

.Usage_wrap_bottom {
    border: 1px solid #a1a1aa45;
    background: #f4f4f5;
    max-width: 575px;
    min-height: 73px;
    padding: 20px;
    position: relative;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.payment_account {
    margin-top: 75px;
}





.topics_elm {
    padding: 8px 22px;
    border-radius: 50px;
    border: 2px solid #d5d5d5;
    cursor: pointer;
}

.topics_elm:hover {
    background: var(--primary);
    color: var(--white);
    border: 2px solid var(--primary);
}

.topics_elm_active {
    background: var(--primary);
    color: var(--white) !important;
    border: 2px solid var(--primary);
}

.org_wrap {
    border: 2px solid #d5d5d5;
    border-radius: 20px;
    overflow: hidden;
}

.org_footer {
    border-top: 1px dashed #71717a;
}

.org_body_content {
    background: #fafafa;
}


/* Clone Social CSS */

.band_wrapper {
    padding: 10px 20px;
    border: 1px solid #a1a1aa45;
    border-radius: 20px;
}

.integrations_body .band_wrapper {
    padding: 22px 20px;
}

.band_img_wrap {
    width: 52px;
    height: 52px;
    border-radius: 100%;
    border: 1px solid #d5d5d5;
    text-align: center;
    line-height: 47px;
}

.band_wrapper button {
    border: 1px solid #a1a1aa45;
    border-radius: 20px;
    width: 85px;
    padding: 6px 15px;
    background-color: #fff;
}

.band_wrapper .addIcon {
    font-size: 20px;
}

.social_wrap .head button {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background: var(--primary);
    color: var(--white);
    border: none;
}

/* integrations css */

.int_icon {
    color: var(--primary);
    font-size: 30px;
}

.prodigy_feature {
    position: relative;
}

.btn_wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.prodigy_feature .details h3 {
    color: #d5d5d5;
}

.prodigy_feature .details p {
    color: #d5d5d5;
}


/* colobrators css */

.creator_wrap {
    border: 1px solid #d5d5d5;
    padding: 10px 20px;
    border-radius: 20px;
}

.creator_wrap .img_wrap {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    overflow: hidden;
    background: var(--primary);
    text-align: center;
    line-height: 34px;
}

.creator_wrap .img_wrap img {
    width: 100%;
    height: 100%;
}

/* notifications css */

.notifications_wrap h3 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.switchbtn {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switchbtn input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: var(--primary);
}

input:focus+.slider {
    box-shadow: 0 0 1px var(--primary);
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.frequencyBox {
    margin-top: 10px;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;

}

.setting_input.message textarea::placeholder {
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.setting_input.msg textarea::placeholder {
    color: var(--text2-color);
    font-size: 14px;
    font-weight: 500;
}

.slectBoxWrap input[type='checkbox'] {
    width: 20px;
    height: 20px;
    accent-color: var(--primary);
}


.slectBoxWrap input[type='checkbox']:checked {
    background-color: green;
}

.slectBoxWrap label {
    font-size: 13px;
    font-weight: 600;
}

.Usage_wrap .progress {
    height: 7px;
}

.Usage_wrap .progress .progress-bar {
    background: var(--primary);
}

.details h3 {
    color: #000;
    font-size: 13px;
}

@media (max-width: 1600px) {

    .theme-modal.ai_img_wrap.content-modal .modal-content {
        height: 680px;
        overflow: hidden;
    }

    .content_modal {
        height: 586px;
    }
}



@media (max-width: 1199px) {
    .login-wrap {
        padding: 0 50px;
    }

    .welcome-txt h2 {
        font-size: 40px;
    }

    .train_nav.welcome-studio .nav-item {
        margin: 10px 0px 16px 10px;

    }

    .welcome-studio {
        justify-content: center;
    }
}

@media (max-width: 1023px) {
    .login-wrap {
        flex-wrap: wrap;
    }

    .login-left {
        width: 100%;
        height: auto;
        min-height: 20px;
        padding-bottom: 30px;
        text-align: center;
    }

    .login-right {
        width: 100%;
    }

    .login-wrap {
        justify-content: center;
        flex-direction: column;
    }

    .welcome-txt {
        width: 100%;
    }
}

@media (max-width:991px) {
    .train_content_wrap .train_flex {
        justify-content: center;
    }


    .theme-modal.ai_img_wrap.content-modal .modal-content {
        height: 540px;
    }

    .content_modal {
        height: 452px;
    }


    .welcome-studio {
        /* padding: 20px 30px; */
        /* border-radius: 60px; */
    }

    .train_nav.welcome-studio .nav-item {
        margin: 10px 0px 16px 10px;

    }
}

@media (max-width: 767px) {
    .login-wrap {
        padding: 0 15px;
    }

    .login-right-main {
        padding: 20px;
    }

    .login-footer {
        position: static;
    }

    .login-header .site-header {
        position: static;
    }
}

/* Added Tapas */

@media (min-width: 1951px) {


    .editor-controller {

        margin-top: 26px;
    }

    .editor-right {
        height: calc(100vh - 325px);
    }
}

@media (max-width: 1700px) {

    .header-in,
    .created-by,
    .video-project,
    .webnair,
    .site-footer,
    .site-container {
        padding-left: 50px;
        padding-right: 50px;
    }



    .webnair {
        padding: 130px 0 45px 0;
    }

    .site-container.for-editor {
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .control-bar-left ul li {
        margin-left: 12px;
    }

    .video-project-img {
        height: 300px;
    }
}

@media (max-width: 1599.95px) {
    .webnair {
        background-size: cover;
    }

    .editor-controller {

        margin-top: 20px;
    }

    /* .video-project-16x9 {
        height: 270px;
    }

    .video-project-img_16x9 {
        height: 196px;

    } */

    .webnair-in {
        max-width: 1250px;
        padding: 50px 0;
    }

    .image-block {
        max-width: 1050px;
    }

    .image-block-left {
        padding-right: 0;
    }

    .image-block-single:nth-child(even) .image-block-left {
        padding-left: 0;
    }

    .template-list ul li {
        width: 20%;
    }

    .account-block {
        padding: 50px;
    }

    .account-wrap {
        max-width: 100%;
    }

    .video-project-img {
        height: 300px;
    }

    .hero-banner {
        /* height: calc(100vh - 208px); */
        height: 79vh;
    }

    /* .video-project-img_9x16 {
        border-radius: 20px 20px 0px 0px;
        overflow: hidden;
        max-height: 481px;
        height: 100%;
    } */

}

@media (max-width: 1366.97px) {

    /* .video-project-16x9 {
        height: 228px;
    }

    .video-project-img_16x9 {
        height: 157px;

    } */
    .editable .editor_16X9 {
        transform: scale(0.8);
    }

    .editor-right {
        padding-left: 0;
    }

    .editor-bar {
        transform: scale(0.9);
        margin: 0 0 0 100px;

    }
}

@media (max-width: 1300px) {
    .editor-bar {
        margin: 0 0 0 0;
        max-width: 100%;
        width: 100%;
        transform: scale(1);

    }

    .welcome-video {
        max-width: 1100px;
    }

    .welcome-top h1 {
        font-size: 50px;
    }

    .create-details {
        padding: 0 30px;
    }

    .create-details p {
        font-size: 13px;
    }

    .create-details h3 {
        font-size: 19px;
    }

    .create-details h4 {
        font-size: 46px;
    }

    .create-details-bottom span {
        font-size: 36px;
    }

    .webnair-in {
        max-width: 1130px;
    }

    .webnair-main {
        max-width: 1000px;
    }

    .footer-left p {
        font-size: 14px;
    }

    .footer-right ul li a {
        font-size: 13px;
    }

    .footer-right ul li {
        margin-left: 10px;
    }
}

@media (max-width: 1199px) {

    .header-in,
    .created-by,
    .video-project,
    .webnair,
    .site-footer,
    .site-container {
        padding-left: 30px;
        padding-right: 30px;
    }

    /* .video-project-16x9 {
        height: 302px;
    }

    .video-project-img_16x9 {
        height: 230px;

    } */

    .welcome-video {
        max-width: 900px;
    }

    /* .video-project-wrap .col-lg-3 {
        width: 33.33%;
    } */

    .image-block {
        max-width: 900px;
    }

    .footer-in {
        flex-wrap: wrap;
        justify-content: center;
    }

    .footer-left {
        margin: 7px 0;
    }

    .footer-left,
    .footer-right {
        align-self: stretch;
        width: 100%;
        text-align: center;
    }

    .footer-right {
        padding-top: 10px;
    }

    .footer-right ul {
        justify-content: center;
    }

    .integration ul li {
        width: 25%;
    }

    .connection-single {
        /* padding: 0 25px; */
    }

    .template-list ul li {
        width: 25%;
    }

    .template-img {
        height: 400px;
        width: 225px;
    }

    .editor-wrap {
        flex-wrap: wrap;
    }

    .editor-left {
        align-self: stretch;
        width: 100%;
    }

    .editor-right {
        padding: 0;
        margin-top: 40px;
        align-self: stretch;
        width: 100%;
    }
}

@media (max-width: 1023px) {
    .welcome-video {
        padding: 0 20px;
    }

    .welcome,
    .image-block {
        padding-left: 30px;
        padding-right: 30px;
    }

    .image-block-single:nth-child(odd) [class*='col-'] {
        order: 2;
    }

    .image-block-single:nth-child(odd) [class*='col-']+[class*='col-'] {
        order: 1;
    }

    .image-block-right {
        text-align: center !important;
    }

    .image-block-left {
        max-width: 600px;
        margin: 0 auto;
        padding-top: 30px;
        text-align: center;
    }

    .webnair-left {
        text-align: center;
    }

    .webnair-right {
        text-align: center;
        max-width: 600px;
        margin: 0 auto;
    }

    .create-details {
        border: 1px solid var(--primary);
    }

    .site-header.fixed .menu_bar i {
        color: var(--white);

    }

    .menu_bar i {
        color: var(--primary);
    }

    .menu_bar {
        display: block;
    }

    .site-nav {
        position: fixed;
        width: 250px;
        height: 100vh;
        border-radius: 0;
        left: -300px;
        top: 0;
        background: var(--dark-blue);
        z-index: 5;
        display: flex;
        /* align-items: center; */
        justify-content: center;
        transition: all 0.5s ease;
        padding-top: 50px;
    }

    .site-nav.act {
        left: 0;
    }

    .site-nav ul {
        flex-wrap: wrap;
        flex-direction: column;
    }

    .template-list ul li {
        width: 33.33%;
    }

    .account-wrap {
        flex-wrap: wrap;
    }

    .account-nav {
        align-self: stretch;
        width: 100%;
    }

    .account-cont {
        align-self: stretch;
        width: 100%;
        padding: 50px 0 0 0;
    }

    .editable {
        transform: scale(0.8);
        width: 90vh;
        margin: 0 auto;
    }

    .editor_16X9 {
        margin-left: -40px !important;
    }
}

@media (max-width:991.98px) {

    .video-project-16x9 {
        margin: 0 auto;
        margin-bottom: 20px;
    }

    .video-project-9x16 {
        margin-top: 0;
        margin-bottom: 20px;
    }

}

@media (max-width: 767px) {

    .welcome,
    .image-block {
        padding-left: 15px;
        padding-right: 15px;
    }

    .welcome-video-in {
        height: 218px;
    }

    .welcome-top h1 {
        font-size: 27px;
    }

    .welcome-top p {
        padding-left: 0;
        padding-right: 0;
    }

    .editable {
        width: 100%;
        margin: 0 auto;
    }

    .header-in,
    .created-by,
    .video-project,
    .webnair,
    .site-footer,
    .site-container {
        padding-left: 15px;
        padding-right: 15px;
    }

    .video-project-wrap .col-lg-3 {
        width: 100%;
    }

    .webnair-in {
        padding: 15px;
    }

    .webnair-right {
        padding-top: 30px;
    }

    .footer-right ul {
        flex-wrap: wrap;
    }

    .footer-right ul li:last-child {
        margin: 0;
        align-self: stretch;
        width: 100%;
        text-align: center;
        padding-top: 10px;
    }

    .webnair {
        padding-top: 60px;
        padding-bottom: 35px;
    }

    .video-project {
        margin-top: 50px;
    }

    .image-block-right {
        max-width: 230px;
        margin: 0 auto;
    }

    .logo img {
        width: 170px;
    }

    .site-header-main {
        height: 66px;
    }

    .site-wrap {
        padding-top: 100px;
    }

    .connection-single {
        /* height: 90px; */
    }

    .integration ul li {
        width: 50%;
    }

    .integration-single {
        border-radius: 10px;
        align-items: center;
        text-align: center;
    }

    .connection-single {
        border-radius: 10px;
        padding: 15px;
    }

    .site-wrap {
        padding-bottom: 50px;
    }

    .theme-modal .modal-content {
        padding: 0;
        border-radius: 5px;
    }

    .dimension {
        height: 140px;
    }

    .dimension h5 {
        font-size: 25px;
    }

    .modal-close {
        right: 10px;
        top: 10px;
    }

    .top-bar {
        flex-wrap: wrap;
    }

    .top-bar label {
        white-space: nowrap;
    }

    .top-bar-right {
        flex-wrap: wrap;
    }

    .top-bar-right .theme-btn {
        align-self: stretch;
        width: 100%;
        margin: 8px 0 0 0 !important;
    }

    textarea.large {
        height: 200px;
    }

    .theme-btn.btn-min {
        min-width: 150px;
    }

    .create-cont {
        margin-top: 0;
    }

    .template-list ul li {
        width: 50%;
    }

    .template-img {
        height: 280px;
        width: auto;
    }

    .help-txt {
        padding: 30px 0;
    }

    .help-txt .theme-btn {
        min-width: 160px;
    }

    .account-wrap {
        padding-top: 0;
    }

    .account-block {
        padding: 30px;
        border-radius: 15px;
    }

    .no-cont {
        padding: 30px 0;
    }

    .editor-left {
        flex-wrap: wrap;
    }

    .editor-left-nav {
        align-self: stretch;
        width: 100%;
    }

    .editor-left-nav .navTab {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    .navTab .nav-link {
        font-size: 20px;
        width: auto;
        height: auto;
        padding: 15px;
        border-radius: 5px;
    }

    .navTab .nav-link.active {
        height: auto;
    }

    .editor-left-cont {
        margin-top: 30px;
        align-self: stretch;
        width: 100%;
        border-radius: 5px;
    }

    .editor-left-cont {
        padding: 15px;
    }

    .editable,
    .editor-controller {
        border-radius: 5px;
    }

    .control-bar {
        padding: 0 10px;
    }

    .control-bar {
        flex-wrap: wrap;
        height: auto;
        padding: 10px;
        border-radius: 0;
    }

    .editor-controller {
        height: auto;
        padding: 15px;
    }

    .editor-wrap {
        margin-top: 0;
    }

    .login-box {
        width: 100%;
        padding-bottom: 50px;
    }
}

@media (max-width: 530.98px) {
    .video-project-16x9 {
        max-width: 100%;
        height: 100%;
        margin-bottom: 0;
    }

    .video-project-wrap .col-xl-4.col-lg-6 {
        margin-bottom: 25px;
    }

    .video-project-wrap .col-xl-3.col-lg-6 {
        margin-bottom: 25px;
    }

    .video-project-img_16x9 {
        max-height: 300px;
    }

    .tabRight {
        padding: 0 26px;
    }

    .tabepaneIn-main {
        padding: 5px;
    }

    .tabTitle.outreach {
        margin-top: 30px;
    }
}




.loader-center-temp1 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00c4a6;
    height: 100%;
}

.select-tile-style {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00c4a6;
    font-size: 28px;
    border: 2px solid #00c4a6;
    border-radius: 20px !important;
}


.photo-single video {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
    /* border-radius: 30px; */
}

.list-delete {
    position: absolute;
    right: 9px;
    top: 9px;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    font-size: 19px;
    cursor: pointer;
    opacity: 0;
}

.photo-single:hover .list-delete {
    opacity: 1;
}

.sketch-background-ui .sketch-picker {
    width: 95% !important;
}

.active-music-file {
    border: 2px solid #f0f5ff;
}

.no-showvideotag {
    visibility: hidden;
    height: 0px;
    width: 0px;
}

.layer-border {
    border: 3px dashed #00c4a6;
    padding: 2px;
    transition: none;
}


.media-loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00c4a6;
    font-size: 25px;
}

.logo-style {
    z-index: 2;
    max-width: 100px;
}

.watermark-op {
    opacity: .5;
}

.logo-top-left {
    position: absolute !important;
    top: 0;
    left: 0;
    padding: 10px;
}

.logo-top-right {
    position: absolute !important;
    top: 0;
    right: 0;
    padding: 10px;
}

.logo-bottom-right {
    position: absolute !important;
    bottom: 0;
    right: 0;
    padding: 10px;
}

.logo-bottom-left {
    position: absolute !important;
    bottom: 0;
    left: 0;
    padding: 10px;
}

.logo-top-center {
    position: absolute !important;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
}

.logo-center-left {
    position: absolute !important;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    padding: 10px;
}

.logo-center {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
}

.logo-center-right {
    position: absolute !important;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 10px;
}

.logo-bottom-center {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
}


.addReelBt {
    position: absolute;
    right: -50px;
    top: 50%;
    margin-top: -17px;
    cursor: pointer;
}

.addReelBt svg {
    font-size: 40px;
    color: #838383;
}

.addReelBt svg:hover {
    color: #00c4a6;
}

.active-tile-border {
    border: 2px solid #00c4a6
}

.deactive-tile-border {
    border: 1px solid #888888
}

.remove_line {
    background: red;
    height: 31px;
    left: 11px;
    position: absolute;
    top: -5px;
    transform: rotate(45deg);
    width: 2px;
}

.editor-background-img {
    height: 100% !important;
    object-fit: contain;
}

.profileLoader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

/* remotion */


.theme-modal.xl .modal-dialog {
    max-width: 768px;
}

.theme-modal .modal-content {
    border-radius: 20px;
    border: none;
    box-shadow: none;
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.1);
}

.theme-modal .modal-body {
    padding: 20px;
}

.theme-modal.remotion-preview_16X9.xl .modal-dialog {
    max-width: 1080px !important;
}


.theme-modal.remotion-preview_16X9 .modal-content {
    height: 700px !important;
    padding: 10px !important;
}

.theme-modal.remotion-preview_16X9 .modal-body {
    padding: 12px !important;
}

.theme-modal.remotion-preview_1X1 .modal-dialog {
    max-width: 715px !important;
}


.theme-modal.remotion-preview_1X1 .modal-content {
    height: 798px !important;
}

.theme-modal.remotion-preview_1X1 .modal-body {
    padding: 12px !important;
}

/* 9X16 */
.theme-modal.remotion-preview_9X16 .modal-dialog {
    max-width: 460px !important;
}


.theme-modal.remotion-preview_9X16 .modal-content {
    height: 820px !important;
}

.theme-modal.remotion-preview_9X16 .modal-body {
    padding: 12px !important;
}

.image-cancle-icon {
    position: absolute;
    top: 0px;
    right: 4px;
    color: #00D0B0;
    cursor: pointer;
}

.publish_text.form-control:focus {
    color: #fff;
    background-color: transparent;
    border-color: none !important;
    outline: 0;
    box-shadow: none;
}


.upload-image-single-narration {
    width: 300px;
    border: 1px solid var(--primary);
    padding: 5px;
    border-radius: 10px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.non-rendering-bar {
    background: #0c8381;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}


.loader {
    width: 80px;
    height: 80px;
    border: 3px var(--primary) solid;
    border-radius: 50%;
    animation: rotation 1s ease-in-out infinite;
    text-align: center;
}

.loader-sec {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: #ffffff; */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.loader::after {
    width: 15px;
    height: 15px;
    background-color: var(--primary);
    border-radius: 100%;
    position: absolute;
    content: "";
}

@keyframes rotation {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}


.play-btn {
    display: none;
}

.welcome-video:hover .play-btn {
    display: block;
    transition: all 5s ease;
}


.card__line {
    position: absolute;
    animation: 2s 2s linear infinite;
    /* display: none; */
}

.card__line_left {
    animation-name: to-top;
    background: linear-gradient(to bottom, #ff6a27, transparent);
    left: 0;
    bottom: -100%;
    width: 0.2em;
    height: 100%;
}

.card__line_right {
    animation-name: to-bottom;
    background: linear-gradient(to bottom, transparent, #ff6a27);
    right: 0;
    top: -100%;
    width: 0.2em;
    height: 100%;
}

.card__line_top {
    animation-delay: 3s;
    animation-name: to-right;
    background: linear-gradient(to right, transparent, #ff6a27);
    width: 100%;
    height: 0.2em;
    top: 0;
    left: -100%;
}

.card__line_bottom {
    animation-delay: 3s;
    animation-name: to-left;
    background: linear-gradient(to right, #ff6a27, transparent);
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 0.2em;
}

@keyframes to-top {
    to {
        bottom: 200%;
    }
}

@keyframes to-bottom {
    to {
        top: 200%;
    }
}

@keyframes to-left {
    to {
        right: 200%;
    }
}

@keyframes to-right {
    to {
        left: 200%;
    }
}

.video_nxt_btn span {
    font-size: 100px;
    color: var(--white);
}


.video_nxt_wrapper {
    height: 100% !important;
    padding-bottom: 40px !important;
    background-color: transparent;


}

.video_nxt_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* margin-top: 42px; */
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    /* border-right: 2px #00d0b0 solid;
    border-bottom: 2px #00d0b0 solid;
    border-top: 2px #00d0b0 solid; */
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
    position: relative;
    overflow: hidden;
}

.video_nxt_btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.5s ease;
    width: 0;
    height: 100%;
    opacity: 1;
    background: #fff;
}

.video_nxt_btn:hover::before {
    width: 100%;
    opacity: 1;
}

.video_nxt_btn:hover span {
    color: var(--secondary);
    position: relative;
    z-index: 2;
}

.create-modal .modal-dialog {
    max-width: 100% !important;
}

.create-modal .created-by {
    padding: 0;
}

.zoominheader {
    background-size: cover;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-animation: zoomin 10s linear infinite;
    animation: zoomin 10s linear infinite;
    transition: all .5s linear;
    overflow: hidden;
}

.zoomoutheader {
    width: 100%;
    background-size: cover;
    -webkit-animation: zoomout 10s linear infinite;
    animation: zoomout 10s linear infinite;
    transition: all .5s linear;
    overflow: hidden;
}

.zoomleft {
    width: 100%;
    background-size: cover;
    -webkit-animation: zoomout 50s linear infinite;
    animation: zoomleft 50s linear infinite;
    transition: all .5s linear;
    overflow: hidden;
}


@-webkit-keyframes zoomleft {
    0% {
        transform: translateX(0%) scale(1.5);
    }

    100% {
        transform: translateX(100%) scale(1);
    }
}

@keyframes zoomleft {
    0% {
        transform: translateX(0%) scale(1.5);
    }

    100% {
        transform: translateX(100%) scale(1);
    }
}

.zoomright {
    width: 100%;
    background-size: cover;
    -webkit-animation: zoomout 50s linear infinite;
    animation: zoomleft 50s linear infinite;
    transition: all .5s linear;
    overflow: hidden;
}


@-webkit-keyframes zoomright {
    0% {
        transform: translateX(-0%) scale(1.5);
    }

    100% {
        transform: translateX(-100%) scale(1);
    }
}

@keyframes zoomright {
    0% {
        transform: translateX(-0%) scale(1.5);
    }

    100% {
        transform: translateX(-100%) scale(1);
    }
}

/* Zoom in Keyframes */
@-webkit-keyframes zoomin {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(2);
    }
}

@keyframes zoomin {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(2);
    }
}

/*End of Zoom in Keyframes */


@-webkit-keyframes zoomout {
    0% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes zoomout {
    0% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

.carousel {
    margin-left: auto;
}



.tabepaneIn-cont {
    overflow: hidden;
    padding: 0 30px 30px;
    border-radius: 10px;
    background: #FFF;
    color: #000;
    border: 1px solid #e6e6e6;
}

.tabepaneIn-cont h3 {
    background: var(--primary);
    color: #fff !important;
    font-size: 18px;
    margin-bottom: 30px;
    margin-left: -30px;
    margin-right: -30px;
    padding: 15px 0 15px 30px;
}

.upgrade-para {
    padding: 10px;
}

.upgrade-para p {
    color: #000 !important;
    font-size: 16px;
}

.tabepaneIn-cont-alt {
    padding-top: 40px;
}

.upgrade-content {
    padding: 0;
}

.upgrade-box-content {
    background: #FFF;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #d3d3d3;
}

.upgrade-heading h6 {
    background-color: var(--primary);
    color: #fff !important;
    padding: 10px;
}

.slide-sec-merge {
    background-color: #000;
    /* padding: 15px; */
    width: 100%;
    z-index: 9;
    height: 125px;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.slide-box {
    align-items: center;
    display: flex;
    overflow-x: auto !important;
    overflow-y: hidden;
    height: 100%;
}

.slide-box ul li {
    align-items: center;
    background-color: #101010;
    /* border: 1px solid #434343; */
    padding: 2px;
    border-radius: 10px;
    color: #9a9a9a;
    display: flex;
    font-size: 14px;
    height: 90px;
    margin: 0 10px;
    text-align: center;
    width: 150px;
    overflow: hidden;
}

.set_resolution span {
    font-family: Poppins;
    font-size: 18px;
}

.set_resolution .form-check-input {
    height: 16px;
    width: 16px;
}

.set_resolution .form-check .form-check-input {
    margin-top: 5px;
}

.set_resolution .form-check-input:checked {
    background-color: #007664;
    border-color: #007664;
}

.playButton {
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: center;
    width: 100%;
    background: #000;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.playButton .playIcon {
    font-size: 25px;
}

.swiper_text {
    width: 100%;
    overflow: hidden;
    position: relative;
    top: 20px;
}

.sketch_right {
    left: -89px !important;
}

.merge-delete {
    color: rgb(0 190 161);
    position: absolute;
    top: 5px;
    right: 3px;
}

.footer-logo a img {
    width: 150px;
}

.slide-box::-webkit-scrollbar {
    height: 5px;
}

.slide-box::-webkit-scrollbar-thumb {
    background: #00D0B0;
    border-radius: 50px;
}

.slide-box::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.30);
    border-radius: 50px;
}

.select-social {
    width: 115px;
    height: 115px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 100%;
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
    transition: none;
}

.sad_talker_img {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 10px;
}

.sad_talker_wrap label {
    margin-top: 5px;
    margin-left: 8px;
}

.sad_talker_wrap .form-check-input {
    width: 20px;
    height: 20px;
}

.sad_talker_wrap .form-check-input:checked {
    background-color: #0c8381;
    border-color: #0c7e7e;
}

.sad_talker_img img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.sad_talker_img video {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.temp-prev {
    padding: 50px 0;
}

.temp-prev img {
    height: 100%;
    width: 100%;
    padding: 2px;
}

.temp-prev video {
    height: 100%;
    width: 100%;
    padding: 2px;
    /* padding: 5; */
    border-radius: 20px;
}

.ck.ck-toolbar {
    max-width: 99%;
}

.fb-card .youtubeicon {
    font-size: 30px;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-60%, -30%);
    transform: translate(-60%, -30%);
}

.upgrade_modal .modalClose {
    text-align: end;
    margin-bottom: 10px;
    margin-top: 10px;
}

.upgrade_modal .modal-body {
    padding: 0px !important;
}

.upgrade_modal .modal-content {
    padding: 0 20px 20px 20px !important;
}

.Talking_Wrap {
    width: 140px;
    height: 140px;
    z-index: 9;
}

.Talking_Wrap_video {
    width: 140px;
    height: 86px;
    margin-right: 16px;
}

.Talking_Wrap_video video {
    width: 100%;
    height: auto !important;
    border-radius: 100%;
}

.Talking_Wrap img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

/* .video_player_render {} */
.Face_narration {
    text-align: center;
}

.Face_narration_section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.Face_narration h2 {
    font-size: 26px;
}

.Face_narration_section span {
    font-size: 26px;
    border-bottom: 1px solid #d1f0d1;
    display: inline-block;
    position: relative;
    top: 5px;
}

.Face_narration_section .Thumb img {
    width: 51px;
    transform: rotateY(180deg);
}

.Face_narration_section .Thumbs img {
    width: 51px;
    transform: rotateY(180deg)rotatex(180deg);
}

.Face_narration_do {
    padding: 10px;
    width: 550px;
    margin-right: 12px;
    overflow: hidden;
}

.Face_narration_do li,
.Face_narration_dont li {
    justify-content: flex-start;
    margin-bottom: 10px;
    margin-top: 8px;
    text-align: left !important;
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding: 0 0 0 100px;
}

.Face_narration_do li span,
.Face_narration_dont li span {
    font-size: 14px;
    color: #fff;
    border: none;
    position: static;

}

.Face_narration_do li .green {
    color: #fff;
    margin-right: 10px;
    font-size: 31px;
    width: 40px;
    padding: 6px;
    height: 40px;
    background: green;
    border-radius: 100%;
}

.Face_narration_img {
    width: 180px;
    height: 180px;
    margin: 0 auto;
    overflow: hidden;
}

.Face_narration_img img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

.Face_narration_dont {
    padding: 10px;
    width: 550px;
}

.Face_narration_dont li .red {
    color: #fff;
    margin-right: 10px;
    font-size: 31px;
    width: 40px;
    padding: 6px;
    height: 40px;
    background: red;
    border-radius: 100%;
}


.facelogo_position .position-single {
    margin-right: 8px;
    cursor: pointer;

}


.position-circle {
    width: 40px;
    border-radius: 6px;
    background: rgba(29, 27, 65, 0.63);
    border: 1px solid rgba(29, 27, 65, 0.63);
    height: 40px;
    margin-top: 8px;
    /* border-radius: 100%; */
}

.face_size span {
    cursor: pointer;
}

.theme-modal.ds .modal-dialog {
    max-width: 743px;
}

.theme-modal.ds .modal-dialog iframe {
    width: 100% !important;
}

.editor_siteWrap {
    min-height: 100vh !important;
    display: flex;
    align-items: center;
}


.tabLeft {
    padding: 0 90px;
}

.outreach {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.tableWrap {
    overflow: hidden;
    padding: 0;
}

.tableWrap .table> :not(caption)>*>* {
    padding: 8px !important;
}

.profileBlock {
    background: #1e3854;
    border: 1px solid #1e2046;
    border-radius: 10px;
}

.tableWrap .table-dark th {
    border-color: #0c8b8a;
}

.tableWrap .table-dark th {
    font-weight: 500 !important;
    text-align: center !important;
}

.tabepaneIn-cont.g {
    padding: 30px !important;
}

.formSec {
    padding: 10px;
}

.formSec .inpField {
    background: none;
    border: none;
    padding: 0;
}

.settingModal .modal-dialog {
    max-width: 1000px !important;
}

.inpField label {
    color: #fff;
    display: block;
    font: 500 16px/16px Montserrat, sans-serif;
    font-size: 17px;
    padding-bottom: 5px;
}

.settingModal .form-group-alt .form-control {
    background: #14122f;
    border: 1px solid #707070;
    border-radius: 8px;
    color: #fff !important;
    height: 70px !important;
    padding: 0px 0 0 30px;
}

.settingModal .form-group-alt .form-control::placeholder {
    color: #fff !important;
}

.settingModal .form-switch .form-check-input {
    height: 20px;
    width: 65px;
}

.settingModal .form-check-input:checked {
    background-color: #00cbac;
    border-color: #00cbac;
}

.editor-background-img_9x16 {
    height: 100% !important;
    object-fit: cover;
    width: auto;
    margin: 0 auto;
    object-position: center;
}

.editable .video-container_9x16 {
    width: 360px !important;
    height: 640px !important;

}

.editable .video-container_9x16 video {
    object-fit: cover;

}

.fetch-loader {
    color: var(--secondary) !important;
    font-size: 20px;
}

.cc-color-override-1277502092 .cc-btn {
    background-color: var(--primary) !important;
    color: var(--white) !important;
}

.edit_loader {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
}

.active-topic {
    border: 1px solid var(--primary);
    color: var(--primary);
}

.view-button {
    color: #fff !important;
    padding: 5px 10px !important;
    border-radius: 25px !important;
    font-size: 13px;
}

.file-uploaded-ui {
    background: var(--primary);
    color: white;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-right: 12px;
}